import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:[],
    isroute:'0',//路由的变化
    userdata:{},//用户的信息
    webdata:[],//网站的基本信息
    newbox:true,//一个用来触发箱子最新掉落的
    socketData:[],
    client_id:"",
  },
  mutations: {
    socketData(state,data){
      if(data.type != "ping"){
        // state.socketData.forEach((item,index)=>{
        //   if(item.type == data.type){
        //     state.socketData.splice(index,1);
        //     return;
        //   }
        // })
        //state.socketData.push(data);
        state.socketData = data;
      }
      
    },
    client_id(state,data){
      state.client_id = data.client_id;
    },
    
    token(state,data){
      state.token = data
    },
    isroute(state,data){
      state.isroute = data
    },
    userdata(state,data){
      state.userdata = data
    },
    // userdatas(state,data){
    //   state.userdata['coin'] = data
    // }, 改变state数据中某一项
    username_up(state,data){
      state.userdata['username'] = data
    }, 
    userimg_up(state,data){
      state.userdata['logo'] = data
    }, 
    usercoin_up(state,data){
      state.userdata['coin'] = data
    }, 
    userintegral_up(state,data){
      state.userdata['integral'] = data
    }, 
    //网站的信息
    webdata(state,data){
      state.webdata = data
    }, 
    newbox(state,data){
      state.newbox = data
    }, 
  },
  actions: {
    
    getSocketData({
      commit,
      dispatch,
    },data){
      if(data.type != "ping"){
        commit("socketData",data);
        switch (data.type){
          case 'init':
            commit('client_id',data);
            break;
          
        }
      }
      
    
      
    },
    
  },
  modules: {
  }
})
