<template>
  <div class="backghui">
    <div data-transfer="true" class="v-transfer-dom">
      <div class="ivu-modal-mask" style="z-index: -1"></div>
      <div class="ivu-modal-wrap case-opened-modal " style="z-index: 104">
        <div class="ivu-modal" style="min-width: 520px">
          <div class="ivu-modal-content">
            <a 
            class="ivu-modal-contenta"
            style="
                position: absolute;
                right: 0%;
                z-index: 9;
                padding-top: 10px;
                font-size: 25px;
            "
            ></a>
            <div class="ivu-modal-header"><div></div></div>
            <div class="ivu-modal-body">
              <div
                class="case-opened"
              >
                <h2 class="case-opened__title"> <span style="opacity:0">X</span> 恭喜获得  <i class="iconfont" @click="isDrawprout">&#xe697;</i></h2>
                <div class="case-opened__amounts">
                  <div class="case-opened__amount">
                    <span class="case-opened__amount__label">共计金额:</span
                    ><span class="case-opened__amount__value"> {{summoney}}</span>
                  </div>
                </div>
                <!-- 中奖全部商品 -->
                <div style="display: flex;justify-content: space-around;" class="datass">
                  <div class="awards awards_opened" v-for="(item,index) in zhongjiangin" :key="index" style="margin: 20px;">
                    <div class="award" style="width: 200px !important;">
                      <div class="award__header" :style="{backgroundImage:'url('+item.back_logo+')'}">
                        <div
                          class="award__picture award__pictures"
                          style="
                            z-index: 10;
                          "
                        ></div>
                        <div
                          class="award__picture goods_color5"
                          style="background-size: 90% width: 200px !important;height: 120px;z-index:99"
                          :style="{backgroundImage:'url('+item.logo+')'}"
                        ></div>
                      </div>
                      <div class="award__content">
                        <h2 class="award__title">{{item.title}}</h2>
                      </div>
                      <div class="award__footer">
                        <span class="award__price">{{item.money}}</span>
                      </div>
                      <div
                        v-if="isaddprizeData != 0"
                        style="
                          display: flex;
                          justify-content: space-around;
                          margin-bottom: 10px;
                          width: 200px;
                        "
                      >
                        <a
                          @click="outc(item.money,index)"
                          href="javascript:;"
                          class="case-opened__link-button"
                          style="font-size: 14px; padding: 5px; margin: 0px"
                          >放入仓库</a
                        ><button
                          @click="hantoCoin(item.w_id,item.money,index)"
                          class="case-opened__button"
                          style="font-size: 14px; padding: 5px"
                        >
                          折入余额
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div class="case-opened__footer">
                  <a href="javascript:;" class="case-opened__link-button" @click="outcs()"
                    >全部放入仓库</a
                  ><button class="case-opened__button" @click="hantoCoins()">全部折入余额</button>
                </div>
                <!-- <div class="case-opened__footer" v-else>
                  <button class="case-opened__button">试玩结束</button>
                </div> -->
              </div>
            </div>
            <div class="ivu-modal-footer"><div></div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props:['isoutDrawp','test','isdemo','zhongjiangin','summoney','isaddprizeData'],
  data(){
    return{
      wid:[],//全部兑换的id
    }
  },
  computed:{ 
    isgetData(){
      if(this.$route.path == '/NineLattice'){
        return false
      }else{
        return true
      }
    }

  },
  methods:{
    //关闭页面
    isDrawprout(){
      this.$emit('update:isoutDrawp',false)
      if(this.isgetData){
        this.$store.commit("newbox", !this.$store.state.newbox);
      }
      this.$message({
        showClose: true,
        message: '全部奖品已为您放入到仓库',
        type: "success",
      });
    },
    //换算余额 单个
    hantoCoin(id,money,inx){
      this.$axios({
        url:'win/winToCoin',
        method:'post',
        data:{
          w_id:id
        }
      }).then( res => {
        if(res.data.status == 200){
          //总价格
          let mone = this.summoney - this.zhongjiangin[inx].money
          mone = mone.toFixed(2)
          this.$emit('update:summoney',mone)

          //出售哪一个 为0关闭
          this.zhongjiangin.splice(inx,1)
          if(this.zhongjiangin == '' && this.isgetData){
            this.$emit('update:isoutDrawp',false)
            this.$store.commit("newbox", !this.$store.state.newbox);
          }
          if(this.isgetData){
            this.$store.commit("newbox", !this.$store.state.newbox);
          }

          //用户余额
          let data = parseFloat(this.$store.state.userdata.coin) + parseFloat(money)
          data = data.toFixed(2)
          this.$store.commit("usercoin_up", data);
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
        }else{
          this.$message({
            showClose: true,
            message: res.data.msg + '或已出售',
            type: "error",
          });
        }
      })
    },
    //换算余额 多个
    hantoCoins(){
      this.wid = []
      if(this.zhongjiangin != ''){
        for(let i = 0;i<this.zhongjiangin.length;i++){
          this.wid.splice(0,0,this.zhongjiangin[i].w_id)
        }
        this.$axios({
          url:'win/winToCoin',
          method:'post',
          data:{
            w_id:this.wid.toString()
          }
        }).then( res =>{
          if(res.data.status == 200){
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "success",
            });
            //总价格
            let mone = parseFloat(this.summoney) + parseFloat(this.$store.state.userdata.coin)
            mone = mone.toFixed(2)
            this.$store.commit("usercoin_up", mone);
            this.$emit('update:isoutDrawp',false)
            if(this.isgetData){
              this.$store.commit("newbox", !this.$store.state.newbox);
            }
          }else{
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "error",
            });
          }
        })
      }
    },
    //放到仓库
    outc(money,inx){
      this.$message({
        showClose: true,
        message: '放入仓库成功',
        type: "success",
      });
      //总价格
      let mone = this.summoney - this.zhongjiangin[inx].money
      mone = mone.toFixed(2)
      this.$emit('update:summoney',mone)
      //出售哪一个 为0关闭
      this.zhongjiangin.splice(inx,1)
      if(this.zhongjiangin == '' && this.isgetData){
        this.$emit('update:isoutDrawp',false)
        this.$store.commit("newbox", !this.$store.state.newbox);
      }
      if(this.isgetData){
        this.$store.commit("newbox", !this.$store.state.newbox);
      }
    },
    //全部放到仓库
    outcs(){
      this.$message({
        showClose: true,
        message: '全部放入仓库成功',
        type: "success",
      });
      this.$emit('update:isoutDrawp',false)
      if(this.isgetData){
        this.$store.commit("newbox", !this.$store.state.newbox);
      }
    }
  },
}
</script>

<style scoped lang="less">
@import url('../assets/csscopy/indexList.css');
.v-transfer-dom{
    position: fixed;
    width: 100%;
    z-index: 110;
    top: 15%;
}
.backghui{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.6);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
}
.award{
  width: 100% !important;
}

.case-opened__title{
  display: flex;
  align-items: center;
  justify-content: space-between;
  i{
    font-size: 20px;
    cursor:pointer
  }
}
.case-opened{
  background-image: url("../assets/img/imgDrawprizes/Drawprizesimg.jpg");
}
.award__header{
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}
@media screen and (max-width: 800px) {
    .ivu-modal{
      width: 96% !important;
      min-width: 100 !important;
      min-width: 96% !important;
    }
    .ivu-modal-contenta{
      right: 8% !important;
    }
    .case-opened__link-button{
      margin-right: 0px !important;
    }
    .datass{
      overflow-y: scroll;
      height: 300px;
      flex-wrap: wrap;
    }
}
</style>