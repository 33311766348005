<template>
  <div id="app">
    <!-- 头部 -->
    <div class="header">
      <div class="page__nav-bar" v-if="isNineLattice">
        <div class="nav">
          <a href="javascript:;" class="logo-item logo-item_img"
            ><img class="logo-item_img" src="../src/assets/img/cslogo.png" alt="" /></a
          >
          <router-link
           to="/"
            aria-current="page"
            class="nav-item  router-link-active"
            :class="isrouter == 1 ? 'router-link-exact-active' : '' "
            ><span class="nav-item__text">CSGO盲盒</span><span class="nav-item__text nav-item__texts">Csgo Case</span></router-link
          >
<!--          <router-link-->
<!--           to="/battleBox"-->
<!--            aria-current="page"-->
<!--            class="nav-item  router-link-active"-->
<!--            :class="isrouter == 8 ? 'router-link-exact-active' : '' "-->
<!--            ><span class="nav-item__text">箱子对战</span><span class="nav-item__text nav-item__texts">Battle Box</span></router-link-->
<!--          >-->
          <router-link  to="/Roll" class="nav-item"
            :class="isrouter == 2 ? 'router-link-exact-active' : '' "
            ><span class="nav-item__text">Roll房</span><span class="nav-item__text nav-item__texts">Rol l room</span></router-link
          >
          <a class="nav-item" @click="hanledNine"
            ><span class="nav-item__text">G 胖保佑</span><span class="nav-item__text nav-item__texts">G bless</span></a
          >
          <router-link to="/freeBox" class="nav-item"
            ><span class="nav-item__text">等级箱子</span><span class="nav-item__text nav-item__texts">Level box</span></router-link
          >
          <router-link to="/recaption" class="nav-item"

            ><span class="nav-item__text">个人背包</span><span class="nav-item__text nav-item__texts">Backpack</span></router-link
          >
          <!-- <a href="javascript:;" class="logo-item"
            ><img src="../src/assets/img/cslogo.png" alt="" /></a
          > -->
          <router-link to="/integral" class="nav-item"
            :class="isrouter == 3 ? 'router-link-exact-active' : '' "
            ><span class="nav-item__text">积分商城</span><span class="nav-item__text nav-item__texts">Integral mall</span></router-link
          ><router-link to="/agency" class="nav-item"
            :class="isrouter == 4 ? 'router-link-exact-active' : '' "
            ><span class="nav-item__text">合作中心</span><span class="nav-item__text nav-item__texts">Partner</span></router-link
          ><router-link to="/recharge" class="nav-item nav-item_highlight"
            :class="isrouter == 5 ? 'router-link-exact-active' : '' "
            ><span class="nav-item__text">充值中心</span><span class="nav-item__text nav-item__texts">Online recharge</span></router-link
          >
          <a v-if="istoken" class="nav-item" style="margin-left: 10%; margin-right: 3%;"
            ><div class="page__nav-bar__sign-in title_text">
              <button class="register-button" @click="handleD()">注册</button
              ><button class="sign-in-button" @click="handleZhu()">登录</button>
            </div></a
          >
          <a style="color: #ffffff;padding:0;margin-left: 12%;margin-right: 1%;" v-else class="nav-item">
            <div class="islogin title_text">
              <div class="islogin_data" @click="$router.push('/userinfo')">
                <img :src=this.$store.state.userdata.logo alt="" />
                <p style="height: 18px;">{{this.$store.state.userdata.username}}</p>
              </div>

              <div class="islogin_wallet" @click="$router.push('/userinfo')" style="margin-right: 30px;">
                <p style="color: #ff9838;"><img src="../src/assets/logo.png" alt=""> : {{this.$store.state.userdata.coin}}</p>
                <!-- <p><img src="../src/assets/jifen.png" alt=""> : {{this.$store.state.userdata.integral}}</p> -->
                <p>积分 : {{this.$store.state.userdata.integral}}</p>
              </div>

              <div class="islogin_out">
                <p @click="$router.push('/recharge')">充值</p>
                <p @click="isout">退出</p>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div>
        <div class="popup form-wnd">
          <div class="switch">
            <div class="button active" @click="handleBU()">
              登录
              <div class="border_bottom"></div>
            </div>
            <div class="button" @click="handleBUt()">
              注册
              <div class="border_bottom"></div>
            </div>
          </div>
          <div class="switch-content">
            <div class="switch-block active">
              <!-- 手机号码登录页 -->
              <div class="wnd-form">
                <div v-if="!isuser" class="input" style="-webkit-user-select:text !important;">
                  <input
                    style="-webkit-user-select:text !important;"
                    autocomplete="off"
                    type="text"
                    placeholder="你的手机号"
                    id="loginName"
                    value=""
                    v-model="phone"
                  /><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAMAAACqTK3AAAAAqFBMVEUAAAD/bmr/lEL/j0j/e17/e13/b2n/fVz/flv/ik3/kkX/fFz/bWr/bmr/fVv/lj//k0P/e13/gVn/cWj/lUL/eWH/flv/g1b/j0j/hlL/c2b/kUb/l0D/bGz/dmL/jUv/iFD/eGD/ik7/lUL/i03/e13/gVj/dGb/hVP/j0j/e13/dmP/dmL/bGz/fVv/d2L/jEz/gVj/hVP/bGv/c2b/iFD/j0j/kUZ6YAsFAAAALXRSTlMAzP47Bf7zyZ1SRinM/vj17+zo39zV1cjIwsG/tayhg4B6aWdlSjUhIRwVZmXY+SFDAAAAuUlEQVQY01WN1w7CMAwATTrZ0L33Brro+P8/I0patdxDcjrZMlAY5353GNgRI6QoCMVbYdDjBHB6oG3OnnHBbbbXUk4GFWMql3RsfSp+e1xS0kZUojZZp8aASjCuU0VvUjH7Ahb0nlxnOn0trNhJGUAmdSJLy1mrb3UtCDX+tDMpT06sUoHjhLQSuSdurNqEZJvshI3KwrvxYIfXvMAa8n3KBwt4Gf6Qebjyl8PC5YCVv4L7wXzpQ3B/gdMQUCA8NDUAAAAASUVORK5CYII="
                    alt="pgcase"
                  />
                </div>
                <div v-if="!isuser" class="input" style="-webkit-user-select:text !important;">
                  <input
                    style="-webkit-user-select:text !important;"
                    autocomplete="off"
                    type="password"
                    placeholder="你的密码(6-16位)"
                    id="loginPwd"
                    value=""
                    v-model="password"
                  /><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAMAAAC3SZ14AAAAh1BMVEUAAAD/bGz/bmr/hVP/ik3/dWT/lz//flv/bmr/jkr/cmf/d2H/fFz/glf/kEf/hFT/h1D/kUb/lUL/ik7/kUb/hVT/mD7/k0T/mD7/bGz/lj//lUL/mD7/bmr/lUL/kUb/k0T/k0P/iFD/h1D/iFD/c2f/d2L/fFz/hFT/i0z/lEP/gVn/j0iwFTxpAAAAJHRSTlMA7u5mdx7+Hv3hw8PDwz4e+fLy7+3b17Wlo3BdNhoGAlVVDQ2urHiJAAAAjElEQVQY023IVw7CMBAA0QUc4jSn90ZLsZP7n48UYdaS52ekB2dNkWVFA6jqflT9hYmo7ro6EkxSLh77niKXlIT9vj5MJK3WeWuVxH/EzzNCeEyOYk4IA3gHH6XgBf6c3lDp7G/kAcrbyFxUWkwwJxeTO2nJGB1MzmhoabAx2YOWWkqvKEpbgPKiVMIXAa0MKfKCZd4AAAAASUVORK5CYII="
                    alt="pgcase"
                  />
                </div>
                <label v-if="!isuser" class="checkbox"
                  ><input
                    id="passw"
                    type="checkbox"
                    name="checkbox-test"
                    class="checkbox-input"
                  /><span class="checkbox-custom"></span
                  ><span class="label">
                    记住密码
                  </span></label
                >
                <div v-if="isuser" class="isuser">
                  <img :src=isuserlogo alt="">
                  <p>{{isusernanme}}</p>
                  <span @click="switchUser">切换其它账号</span>
                </div>
                <label class="checkbox"
                  ><input
                    id="limit18"
                    type="checkbox"
                    name="checkbox-test"
                    class="checkbox-input"
                  /><span class="checkbox-custom"></span
                  ><span class="label">
                    确认已满18岁，盲盒娱乐理想消费，切勿上头
                  </span></label
                >
                <button id="loginBtn" class="form-button" @click="handelLogin">
                  <span>登录</span></button
                >

                <a
                  v-if="!isuser"
                  @click="handleWj"
                  href="javascript:;"
                  id="forgetPwdSendEmail"
                  class="center-link popup-btn"
                  >
                  <!-- <img
                    src="http://r8skins.com/img/forget-pwd.09e023f9.png"
                    alt="pgcase"
                    style="width: 25px; vertical-align: top"
                  /> -->
                  忘记密码?
                </a>
              </div>


            </div>
            <div class="switch-block">
              <form action="" method="post" id="reg" class="wnd-form">
                <div class="input" style="-webkit-user-select:text !important;">
                  <input
                    style="-webkit-user-select:text !important;"
                    type="tel"
                    placeholder="你的手机号"
                    name="registerEmail"
                    id="registerEmail"
                    autocomplete="off"
                    v-model="phone"
                  /><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAMAAACqTK3AAAAAqFBMVEUAAAD/bmr/lEL/j0j/e17/e13/b2n/fVz/flv/ik3/kkX/fFz/bWr/bmr/fVv/lj//k0P/e13/gVn/cWj/lUL/eWH/flv/g1b/j0j/hlL/c2b/kUb/l0D/bGz/dmL/jUv/iFD/eGD/ik7/lUL/i03/e13/gVj/dGb/hVP/j0j/e13/dmP/dmL/bGz/fVv/d2L/jEz/gVj/hVP/bGv/c2b/iFD/j0j/kUZ6YAsFAAAALXRSTlMAzP47Bf7zyZ1SRinM/vj17+zo39zV1cjIwsG/tayhg4B6aWdlSjUhIRwVZmXY+SFDAAAAuUlEQVQY01WN1w7CMAwATTrZ0L33Brro+P8/I0patdxDcjrZMlAY5353GNgRI6QoCMVbYdDjBHB6oG3OnnHBbbbXUk4GFWMql3RsfSp+e1xS0kZUojZZp8aASjCuU0VvUjH7Ahb0nlxnOn0trNhJGUAmdSJLy1mrb3UtCDX+tDMpT06sUoHjhLQSuSdurNqEZJvshI3KwrvxYIfXvMAa8n3KBwt4Gf6Qebjyl8PC5YCVv4L7wXzpQ3B/gdMQUCA8NDUAAAAASUVORK5CYII="
                    alt="pgcase"
                  />
                </div>
                <div class="input" style="-webkit-user-select:text !important;">
                  <input
                    style="-webkit-user-select:text !important;"
                    type="password"
                    placeholder="你的密码(6-16位)"
                    name="registerPwd"
                    id="registerPwd"
                    autocomplete="off"
                    v-model="password"
                  /><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAMAAAC3SZ14AAAAh1BMVEUAAAD/bGz/bmr/hVP/ik3/dWT/lz//flv/bmr/jkr/cmf/d2H/fFz/glf/kEf/hFT/h1D/kUb/lUL/ik7/kUb/hVT/mD7/k0T/mD7/bGz/lj//lUL/mD7/bmr/lUL/kUb/k0T/k0P/iFD/h1D/iFD/c2f/d2L/fFz/hFT/i0z/lEP/gVn/j0iwFTxpAAAAJHRSTlMA7u5mdx7+Hv3hw8PDwz4e+fLy7+3b17Wlo3BdNhoGAlVVDQ2urHiJAAAAjElEQVQY023IVw7CMBAA0QUc4jSn90ZLsZP7n48UYdaS52ekB2dNkWVFA6jqflT9hYmo7ro6EkxSLh77niKXlIT9vj5MJK3WeWuVxH/EzzNCeEyOYk4IA3gHH6XgBf6c3lDp7G/kAcrbyFxUWkwwJxeTO2nJGB1MzmhoabAx2YOWWkqvKEpbgPKiVMIXAa0MKfKCZd4AAAAASUVORK5CYII="
                    alt="pgcase"
                  />
                </div>
                <div class="input" style="-webkit-user-select:text !important;">
                  <input
                    style="-webkit-user-select:text !important;"
                    type="text"
                    placeholder="验证码"
                    name="registerPwd2"
                    id="registerPwd2"
                    autocomplete="off"
                    v-model="verification"
                  /><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAMAAAC3SZ14AAAAh1BMVEUAAAD/bGz/bmr/hVP/ik3/dWT/lz//flv/bmr/jkr/cmf/d2H/fFz/glf/kEf/hFT/h1D/kUb/lUL/ik7/kUb/hVT/mD7/k0T/mD7/bGz/lj//lUL/mD7/bmr/lUL/kUb/k0T/k0P/iFD/h1D/iFD/c2f/d2L/fFz/hFT/i0z/lEP/gVn/j0iwFTxpAAAAJHRSTlMA7u5mdx7+Hv3hw8PDwz4e+fLy7+3b17Wlo3BdNhoGAlVVDQ2urHiJAAAAjElEQVQY023IVw7CMBAA0QUc4jSn90ZLsZP7n48UYdaS52ekB2dNkWVFA6jqflT9hYmo7ro6EkxSLh77niKXlIT9vj5MJK3WeWuVxH/EzzNCeEyOYk4IA3gHH6XgBf6c3lDp7G/kAcrbyFxUWkwwJxeTO2nJGB1MzmhoabAx2YOWWkqvKEpbgPKiVMIXAa0MKfKCZd4AAAAASUVORK5CYII="
                    alt="pgcase"
                  /><span class="sign-up" @click="handleveri()"
                    >获取验证码{{ count }}</span
                  >
                </div>
                <div class="input invite" style="-webkit-user-select:text !important;">
                  <input
                    style="-webkit-user-select:text !important;"
                    type="text"
                    placeholder="邀请码（选填）"
                    name="registerPwd2"
                    id="registerPwd2"
                    autocomplete="off"
                    v-model="invitation"
                  /><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAMAAACqTK3AAAAAqFBMVEUAAAD/bmr/lEL/j0j/e17/e13/b2n/fVz/flv/ik3/kkX/fFz/bWr/bmr/fVv/lj//k0P/e13/gVn/cWj/lUL/eWH/flv/g1b/j0j/hlL/c2b/kUb/l0D/bGz/dmL/jUv/iFD/eGD/ik7/lUL/i03/e13/gVj/dGb/hVP/j0j/e13/dmP/dmL/bGz/fVv/d2L/jEz/gVj/hVP/bGv/c2b/iFD/j0j/kUZ6YAsFAAAALXRSTlMAzP47Bf7zyZ1SRinM/vj17+zo39zV1cjIwsG/tayhg4B6aWdlSjUhIRwVZmXY+SFDAAAAuUlEQVQY01WN1w7CMAwATTrZ0L33Brro+P8/I0patdxDcjrZMlAY5353GNgRI6QoCMVbYdDjBHB6oG3OnnHBbbbXUk4GFWMql3RsfSp+e1xS0kZUojZZp8aASjCuU0VvUjH7Ahb0nlxnOn0trNhJGUAmdSJLy1mrb3UtCDX+tDMpT06sUoHjhLQSuSdurNqEZJvshI3KwrvxYIfXvMAa8n3KBwt4Gf6Qebjyl8PC5YCVv4L7wXzpQ3B/gdMQUCA8NDUAAAAASUVORK5CYII="
                    alt="pgcase"
                  />
                </div>
                <label class="checkbox"
                  ><input
                    id="selectOne"
                    type="checkbox"
                    name="checkbox-test"
                    class="checkbox-input"
                  /><span class="checkbox-custom"></span
                  ><span class="label">
                    我接受《
                    <a href="/treatyPolicy" target="_blank">使用条款</a> 和
                    <a href="/treatyPolicy" target="_blank">隐私政策</a> 》
                  </span></label
                >
                <div
                  id="activation"
                  class="form-button"
                  @click="handleRegister"
                >
                  <span>注册并登录</span>
                </div>
              </form>
            </div>
          </div>
          <div class="popup-closed">
            <i
              class="iconfont icon-guanbi"
              style="font-size: 24px; font-weight: bold"
              @click="handleGuanbi"
              ></i
            >
          </div>
        </div>
        <div class="popup-bg"></div>
      </div>
      <div>
        <div class="popup form-wnd">
          <div class="switch">
            <div class="button active" style="border: none">
              重置密码
              <div class="border_bottom"></div>
            </div>
          </div>
          <div class="switch-content">
            <div class="switch-block active">
              <div class="wnd-form">
                <div class="input" style="-webkit-user-select:text !important;">
                  <input
                    style="-webkit-user-select:text !important;"
                    autocomplete="off"
                    type="text"
                    placeholder="你的手机号"
                    id="loginName"
                    value=""
                    v-model="phone"
                  /><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAATCAMAAACqTK3AAAAAqFBMVEUAAAD/bmr/lEL/j0j/e17/e13/b2n/fVz/flv/ik3/kkX/fFz/bWr/bmr/fVv/lj//k0P/e13/gVn/cWj/lUL/eWH/flv/g1b/j0j/hlL/c2b/kUb/l0D/bGz/dmL/jUv/iFD/eGD/ik7/lUL/i03/e13/gVj/dGb/hVP/j0j/e13/dmP/dmL/bGz/fVv/d2L/jEz/gVj/hVP/bGv/c2b/iFD/j0j/kUZ6YAsFAAAALXRSTlMAzP47Bf7zyZ1SRinM/vj17+zo39zV1cjIwsG/tayhg4B6aWdlSjUhIRwVZmXY+SFDAAAAuUlEQVQY01WN1w7CMAwATTrZ0L33Brro+P8/I0patdxDcjrZMlAY5353GNgRI6QoCMVbYdDjBHB6oG3OnnHBbbbXUk4GFWMql3RsfSp+e1xS0kZUojZZp8aASjCuU0VvUjH7Ahb0nlxnOn0trNhJGUAmdSJLy1mrb3UtCDX+tDMpT06sUoHjhLQSuSdurNqEZJvshI3KwrvxYIfXvMAa8n3KBwt4Gf6Qebjyl8PC5YCVv4L7wXzpQ3B/gdMQUCA8NDUAAAAASUVORK5CYII="
                    alt="pgcase"
                  />
                </div>
                <div class="input" style="-webkit-user-select:text !important;">
                  <input
                    style="-webkit-user-select:text !important;"
                    autocomplete="off"
                    type="password"
                    placeholder="你的密码(6-16位)"
                    id="loginPwd"
                    value=""
                    v-model="password"
                  /><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAMAAAC3SZ14AAAAh1BMVEUAAAD/bGz/bmr/hVP/ik3/dWT/lz//flv/bmr/jkr/cmf/d2H/fFz/glf/kEf/hFT/h1D/kUb/lUL/ik7/kUb/hVT/mD7/k0T/mD7/bGz/lj//lUL/mD7/bmr/lUL/kUb/k0T/k0P/iFD/h1D/iFD/c2f/d2L/fFz/hFT/i0z/lEP/gVn/j0iwFTxpAAAAJHRSTlMA7u5mdx7+Hv3hw8PDwz4e+fLy7+3b17Wlo3BdNhoGAlVVDQ2urHiJAAAAjElEQVQY023IVw7CMBAA0QUc4jSn90ZLsZP7n48UYdaS52ekB2dNkWVFA6jqflT9hYmo7ro6EkxSLh77niKXlIT9vj5MJK3WeWuVxH/EzzNCeEyOYk4IA3gHH6XgBf6c3lDp7G/kAcrbyFxUWkwwJxeTO2nJGB1MzmhoabAx2YOWWkqvKEpbgPKiVMIXAa0MKfKCZd4AAAAASUVORK5CYII="
                    alt="pgcase"
                  />
                </div>
                <div class="input" style="-webkit-user-select:text !important;">
                  <input
                    style="-webkit-user-select:text !important;"
                    type="text"
                    placeholder="验证码"
                    name="registerPwd2"
                    id="registerPwd2"
                    autocomplete="off"
                    v-model="verification"
                  /><img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAUCAMAAAC3SZ14AAAAh1BMVEUAAAD/bGz/bmr/hVP/ik3/dWT/lz//flv/bmr/jkr/cmf/d2H/fFz/glf/kEf/hFT/h1D/kUb/lUL/ik7/kUb/hVT/mD7/k0T/mD7/bGz/lj//lUL/mD7/bmr/lUL/kUb/k0T/k0P/iFD/h1D/iFD/c2f/d2L/fFz/hFT/i0z/lEP/gVn/j0iwFTxpAAAAJHRSTlMA7u5mdx7+Hv3hw8PDwz4e+fLy7+3b17Wlo3BdNhoGAlVVDQ2urHiJAAAAjElEQVQY023IVw7CMBAA0QUc4jSn90ZLsZP7n48UYdaS52ekB2dNkWVFA6jqflT9hYmo7ro6EkxSLh77niKXlIT9vj5MJK3WeWuVxH/EzzNCeEyOYk4IA3gHH6XgBf6c3lDp7G/kAcrbyFxUWkwwJxeTO2nJGB1MzmhoabAx2YOWWkqvKEpbgPKiVMIXAa0MKfKCZd4AAAAASUVORK5CYII="
                    alt="pgcase"
                  /><span class="sign-up" @click="handleveri()"
                    >获取验证码{{ count }}</span
                  >
                </div>
                <button id="loginBtn" class="form-button" @click="handelReset">
                  <span>重置密码并登录</span>
                </button>
              </div>
            </div>
          </div>
          <div class="popup-closed" @click="handleGuan">
            <i
              class="iconfont icon-guanbi"
              style="font-size: 24px; font-weight: bold"
              ></i
            >
          </div>
        </div>
        <div class="popup-bg"></div>
      </div>
    </div>

    <!-- 手机头部 -->
    <div class="headerapp">
      <img class="logoimg" src="../src/assets/img/cslogo.png" alt="" @click="$router.push({path:'/'})">
      <div class="user_data">
        <div class="user_data_token" v-if="!istoken">
          <div class="user_data_data" @click="$router.push('/userinfo')">
            <img class="user_data_img" :src=this.$store.state.userdata.logo alt="">
            <p class="user_name">{{this.$store.state.userdata.username}}</p>
          </div>
          <div user_data_li @click="$router.push('/userinfo')">

            <p>余额 <span class="user_wallet">{{this.$store.state.userdata.coin}}</span></p>
            <p>积分 <span class="user_walletjf">{{this.$store.state.userdata.integral}}</span></p>
          </div>


          <el-button style=" margin:0 5px;" type="primary" @click="$router.push('/recharge')">充值</el-button>
        </div>
        <button v-else data-v-7ba5bd90="" @click="handleZhu()" class="sign-in-button">登录</button>
        <!-- <img @click="handleisBar" style="padding-right: 10px;width: 44px;" data-v-628429d6="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYBAMAAAASWSDLAAAAIVBMVEUAAAD////////////////////////////////////////PIev5AAAACnRSTlMAxGPzQMZGgHNVtC1JCAAAAI9JREFUGNOFjzESAUEQRb+xSpVIkYlGIBGRijaRiAQiEddwBJEjWKzin1J//oZbOnhdL5ip10hjcgTgWB2ADWNKJPINTCRDnIMXrCSLJD4h8toTa8tApKUjVha/8W+nYMac39UnX0ARBTsAszqjfZrQKbnMDi3EB/Za27ac/+LjuuLdob8THJrEWxO6joLyA9ylgpViQtRDAAAAAElFTkSuQmCC" alt="" class=""> -->
        <i class="iconfont" @click="handleisBar" style="font-size: 25px;padding-right: 10px;" :class="isBar ? 'mobileBar_i' : ''">&#xe664;</i>
      </div>
      <div class="mobileBar" v-show="isBar">
        <div class="mobileBar_li" @click="$router.push('/')" :class="isrouter == 1 ? 'mobileBar_lis' : '' ">
          <i class="iconfont">&#xe646;</i>
          <span>CSGO盲盒</span>
          <span class="nav-item__text_m">Csgo Case</span>
        </div>
<!--        <div class="mobileBar_li" @click="$router.push('/battleBox')" :class="isrouter == 8 ? 'mobileBar_lis' : '' ">-->
<!--          <i class="iconfont">&#xe724;</i>-->
<!--          <span>箱子对战</span>-->
<!--          <span class="nav-item__text_m">Battle Box</span>-->
<!--        </div>-->
        <div class="mobileBar_li" @click="$router.push('/Roll')" :class="isrouter == 2 ? 'mobileBar_lis' : '' ">
          <i class="iconfont">&#xe724;</i>
          <span>Roll房</span>
          <span class="nav-item__text_m">Rool room</span>
        </div>
        <div class="mobileBar_li" @click="hanledNine">
          <i class="iconfont">&#xe87a;</i>
          <span>G 胖保佑</span>
          <span class="nav-item__text_m">Backpack</span>
        </div>
        <div class="mobileBar_li" @click="$router.push('/freeBox')" :class="isrouter == 6 ? 'mobileBar_lis' : '' ">
          <i class="iconfont">&#xe610;</i>
          <span>等级箱子</span>
          <span class="nav-item__text_m">Level box</span>
        </div>
        <div class="mobileBar_li" @click="$router.push('/recaption')" :class="isrouter == 7 ? 'mobileBar_lis' : '' ">
          <i class="iconfont" style="font-size: 22px;">&#xe61d;</i>
          <span>个人背包</span>
          <span class="nav-item__text_m">Backpack</span>
        </div>
        <div class="mobileBar_li" @click="$router.push('/integral')" :class="isrouter == 3 ? 'mobileBar_lis' : '' ">
          <i class="iconfont" style="font-size: 22px;">&#xe641;</i>
          <span>积分商城</span>
          <span class="nav-item__text_m">Integral mall</span>
        </div>
        <div class="mobileBar_li" @click="$router.push('/agency')" :class="isrouter == 4 ? 'mobileBar_lis' : '' ">
          <i class="iconfont" style="font-size: 20px;">&#xe63e;</i>
          <span>合作中心</span>
          <span class="nav-item__text_m">Partner</span>
        </div>
        <div class="mobileBar_li" @click="$router.push('/recharge')" :class="isrouter == 5 ? 'mobileBar_lis' : '' ">
          <i class="iconfont">&#xe615;</i>
          <span>充值中心</span>
          <span class="nav-item__text_m">Online recharge</span>
        </div>
        <div class="mobileBar_li" @click="isout" v-if="!istoken">
          <i class="iconfont">&#xe69c;</i>
          <span>退出</span>
          <span class="nav-item__text_m">Out</span>
        </div>

      </div>
    </div>

    <!-- 分隔div -->
    <div style="height: 100px" class="division" v-if="isNineLattice"></div>

    <!-- 中奖轮播图 -->
    <div class="bigop" v-show="isnewbox">
    <div class="bigop_li">
      <div class="win_information" v-for="(item,index) in monitordata" :key="index">
        <div class="win_information_data" :style="{backgroundImage:'url('+item.back_logo+')'}">
          <img :src=item.logo alt="">
        </div>
        <div class="win_information_user">
          <div class="win_information_user_img" :style="{backgroundImage:'url('+item.p_back_logo+')'}">
            <img :src=item.uLogo alt="">
            <h2>{{item.username}}</h2>
          </div>
        </div>

        <p>{{item.title}}</p>
        <div class="bottomdiv" :style="{backgroundImage:'url('+item.back_logo+')'}"></div>
      </div>
    </div>
    <!-- <button @click="big()">Big</button>
    <button @click="small()">Big</button> -->
    </div>

    <!-- 通知条 -->
    <Notification></Notification>

    <router-view/>

    <!-- 底部侧边栏通用 -->
    <div>
      <div class="footer">
        <div class="page__footer__copy">
          <div class="app-container" style="justify-content: center">
            @ CXSKINS 版权所有
            <a href="https://beian.miit.gov.cn" style="margin-left: 15px;color: white" target="_blank">桂ICP备2021004548号-1</a>
          </div>
        </div>
      </div>
    </div>
    <div class="page__sidebar-container" style="z-index: 200">
      <div class="sidebar sidebar_show">
        <button class="sidebar__toggle-button" @click="functionbar">
          <span class="sidebar__toggle-button__text">❯</span></button
        ><a
          :href=this.$store.state.webdata.qq_qun_url
          target="_blank"
          class="sidebar__item"
          ><div
            class="picture-container sidebar__item__icon"
            style="padding-top: 60%; width: 60%"
          >
            <div
              class="picture"
              style="
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAEmklEQVRYR+2Xa2gcVRSAv7O7Fo0Bi1JtWhsFi6kPkj9qG0VJDT5S0bqzsSparShWCylK/WHF0EgTLb4QrY+oAS1Fw6Y7aRvxGYtYbStGEuojRIuKEltftFCEapM5MrO7eTRzZ2azKPmRCwvDnsf95tzzuCNM8SVTnI9pwGJPaDqC/0sEtZN1KOcB1UAJsA/YQZxHZSmHwyDU5haElSjzgDOBj4Ae4rTKUs+XcQUesW5hIXFslDkGD5+LxUV5maaZy3HMkiR9I/91sglleQDBaknynEkeDOiimS2bJMkjrlgztCLUAmfl1A8h7GSYFkro5wgdwJUBgaoVix1+ciOg2jwBPBAUOd3KuTh8HXjEQhMObQg2cKGvrvAHymKx+OpYeRDgD7l88fOZEgtbbc+hm5vBK8ZlwD847Ak4kQZJsjESoG5lHg4/BUYvWzhNYWw5+QBDXECCL4CzDVFskyR3RQPMsAhht2HzzWKxXDvZjnJtRECIcQnDXjWvMti8KxZ10QDTzCbBfoOjDWKxVjsZDKhuP9N7UX7N5eJEubJRUjREAvQq0yYN3DDBk5KRFPVq8ydwcuQICqtdt6ihpThcLfW8Vwig25R3+QDsF4s5avMZjPbAUFAhiXIjcJPPS7dJamL+uXphfdCNoBvJ8UupJsZVBRRJH8JilL3gTZOxq0MslpleMPSyoB3UEGcdUDPiRHgfhxXAxwjzQ6PnkPSK5Ni+Kow0+0kBahclHOU28ABnj3PiNmD4BKU7BHANQj/K2z567Tg8LvX0FgSoaU4iTjPi5cXxvsbKU8AzCKcAt4P3Iu5zdgldKC+LxVua4T6ENcDpBpAPiPGaXM8boUWiNucjtKJc7OtM6MFhvaTY7h1/gltR7jRsvNuF5C86OJEKhAd9O8Oo8cNi0TLW17gc1E7qUNqAMsOGHQh3E2M+wzxfQBX/iNIsKdo0QwvCQ8a0cKOeYuXoQeSeNMMZiNc6TjPBudWmndSibALjFcycklnIRrV5EbjHqCg0S5LGbKbkAYNvL14rUBsL2AycEFq5ZoXXxWKF2rzp2xOzdocYYpEsY8AD1G1UMuxFz1QQ1QjfAzuNw74QYqEBh56Aee/OHG/0ZQEzPJmrMr9t2sXiZrV5Gri/EI6AIxyglIUc5qWAKMIQZVlAmw+Byw0O6xCORuh3hbErjbgdAd4JMKzLAx4EZvooHhCLstCkLgwtq63slRRVmuG7gGm0VnQLC4jRb9hjj1hUa4ZPEUNfnAxc3maImSR4Fag3uGkXTTOLBL8ZFbL5535alhbDYrC9FKUGYb2vXHkhf8Ruh3/MR2kDeD3vm/8Azm1yq1B+Brom+Fd2uRNqbB+8AqECxxtJFcAChD5Jcp3aBH1ATZ5dWILDXIRXUH5H6EbpJ863HGSb3MER81ed+xEe5xxJ0e2NQIdnI12touLmrlpqU0WSL0Vw/ExD74N5I01TygwqcagEqlAqEe85PDeVfQi93g/6mEGvXMOBKO8SGdDkTG1OJU45w5QjlKPEiDGIwyAJ7/eLLOHvKDBFRXCyGxRrV3QEiwUIs58GDItQmHzKR/Bf8BBme3jVDykAAAAASUVORK5CYII=');
                background-size: cover;
              "
            ></div>
          </div>
          <span class="sidebar__item__label">加入<br />官方群</span></a
        ><a
          :href="'http://tool.gljlw.com/qq/?qq='+ this.$store.state.webdata.qq "
          target="_blank"
          class="sidebar__item online_serve"
          >
          <div
            class="picture-container sidebar__item__icon"
            style="padding-top: 60%; width: 60%"
          >
            <div
              class="picture"
              style="
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAFJ0lEQVRYR82YfYhUVRTAf+ftGBZBYiIWlEUFUhllSWFmSv5hou2+N+5mkbqSZR9bVEiQ/eEKkaRZW6yJS5Z9ke3uvFk1kTAxis2EFqvVtaVCIyHKQinQDd05ce+8Gef7zYwjeOAxw5t7zv3Nufeejyuc5yLnOR9nDaidXMwIppDgchwuA/4FfsfhJ6nnh7N1QFWAFirCQ8B9wL1FIZSfgR3UsVka+Loa2IoBNc4KlAXANRVOGEdYJy67KtErG1C7mE4dK4DpGRPsBPbbJ8EBTrCfkYwlwg3AjcFzDzAuraOslSjLyoUsC1B9GoHODKODwGrxeCdsIt3KlZymBXgSuCgY3yUeTWG65vdQwDw4YTUnWSMP8pcxoD1MZJiJCBPBPn8g9AP9KP3i8acd51uPGsjHKoEsCajbGccQXyFcGxhdJB7va5xRJGhGWATcXNITSjvDtEsTxusGtD0ANe5pFZeVpfRLA8Z4E+Epa0BZLlFWBYekGbiqnCUKxhxHaOd7Vkorp9XHB9zA7hyJsr2YraKA2sNdJPgyMLJeojwRwLVWAJY9VNjGKZqJEAE+BSYjfCIu8ysHjNGNEAUGxWPCWcOdIejlNAuIMAPYaF/XMVnq+bYQZEEPaoxJCH2B91YjHALWV+25fMUDODTZ0JQ8qqvEZXn5gL6NU2usgsOdJNgQxLQaMlq4/4BJQK94TC0LUDsZTYSPgFkIn6F8noatDq8LmANcmKP+D/Ab2KBuZBDh49xTnbXE2s1sHD4ARgeu34ZyEzC+OjarZQKyCdCb8mwIrSjZh07pkChLU2OzAeO8i2JCSO1EuUKiHFHfpsSUt4rbV45KlLGFAX20dmTWUot4rDNfNM6jqN3LoSLemQyX7cHaAfYhdIhLR4pGfS4FfgEuCSOsLaAyhDAADNhPZUA8thSCUB9zYOade0ClA4c9FsrhoNTbKjpU1Gch8F7YwOo9aCpkh6fFZUfYJAU9uJsIxxiyuaOEVA9YRvURBq4+u3OK3myVkqc4Ri/ClKKTDDNDGvkiDKLU7zmAh/OqImWjRFlSOMwky3rzD5Mi7LW9hzImeNMknt3oVUsWoLIYYTbYit3IThwelgabYQKEnKm0h+sZtp3a3xJlk8Z5BeX5YNiH4tmGqWpRn4PABOCweFxtDGmczSj3U8d1Um87wQwfhUxlS/pEur/9VbyKCtU86+pzEhiJsExc1uoWbmGYb4Bd4llvZkloT2L/YYyXEV4IfP6MuLyRsqI+s7iAvTKHY+l32xjDKabZtOmwUFyOWztmrOmTkzAzg3cvAS8iLM0M7EWXuJhDNc5OlJkohxDuSDdDZksk6AaOIPSi3A7cbQuEBK7MoycNHuMthGmmFpQGG9wNtPk8IR63FZq7LA+mJ0g27ab6eFs8Hkm/T+7b5wy4LQhMaW+qEs+W9VaCXNyCw/wMONO2moOyRNyguq5miTN1bOmPTfxt4gVFbdg+7uYB6liEQ2Mq66jPa8CzwBbxaChmoiIP5oA+Lm55bYC5lUjFT40xHofmYCXWiJeOEAUZqwYsZC0TJPf3IBqYWnOxjbBCW1hPbGzUBDC4t3kVuBXosye3gQHtZgKOvcsxh8YEY5ODNzCCNpnLjyE7Iwga5YwK22P5+dU0ROb2a2TyhHAUYQ91tEl9RqYqY+7aeDDOVpS5GfMNovaabR/KdyTYJ00Ml8GTN6Q2gDF7T/M6MCrw2FSJ0lsNUK5OTQDtKppYqDQidKXi3HkFWAuYQjZq5sFzBfg/Q4erOOtAigwAAAAASUVORK5CYII=');
                background-size: cover;
              "
            ></div>
          </div>
          <span class="sidebar__item__label">咨询<br />客服</span></a
        >
      </div>
      <!-- 返回顶部 -->
      <!-- <div
        class="goto-top ivu-back-top"
        style="bottom: 0px; right: 30px"
        @click="hanledingbu"
      >
        <a href="javascript:;" title="返回顶部" style="width: 100%"
          ><div
            class="picture-container goto-top__icon"
            style="padding-top: 40%; width: 40%; margin-left: 18px"
          >
            <div
              class="picture"
              style="
                background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAhFBMVEUAAAD/rgD/rgD/rgD/rgD/rgD/sgD/rwD/ogD/rgD/rwD/rQD/rwD/rgD/rgD/rgD/rgD/rgD/rgD/rgD/rgD/rwD/rgD/rwD/rwD/rgD/rQD/rwD/wgD/rgD/rgD/rwD/rgD/rQD/rwD/rgD/rgD/sAD/rgD/rQD/rQD/rwD/rQD/rgBkP2FRAAAAK3RSTlMA89GOVRwPCgb8Py0kx8Kzf/fo3aJD7OLNXTMXAdaqqJqZdXFtO7aclnlLM/ecKgAAAS1JREFUOMullOmOgyAURi9acd9rXeq0nS6zfe//fjNIGm0AMZkTf0A4EO6CpJJ0Lm1hByDjdq+AoBpsngtJGK97Nzwpj2veAzMOmfEZFlzM4hdeSEyeh4m8/36Tg9XMlMMcvKf3gkosRnKSi7GhQpFYqxf1QaoX72ItI0kjJszXinvlRERaMYXgIScnCG5aUeaETdm7YqLQii0mwiL6+QBgDPsMFbZTvRxaFPMOA4HSNwraa/Yw0b6K7zBxUKpigCkdtu3EAQqGp5MB2xIZT929jCmd7p1p/yQVfc6iJ/Y6ASk0jtjuPL0rRQhPI+mIj+IpJo34/oZj7NEKfUQJp5iTBR8HQh3gbBc7QsdxsYsphSnH3iZy1BQ6AXNtYlDlFLbEfbKaI6GkbTCH/sEvIzFT0r6A0QIAAAAASUVORK5CYII=');
                background-size: cover;
              "
            ></div>
          </div>
          <span class="goto-top__label">TOP</span></a
        >
      </div> -->

      <!-- 红包 -->
      <div class="password-red-envelope page__password-red-envelope">
        <div
          class="picture-container password-red-envelope__enter-button"
          style="padding-top: 79.3651%; width: 100%"
          @click="redPrize"
        >
          <div
            class="picture pictureRed"
            style="
              background-size: cover;
            "
          ></div>
        </div>
      </div>
      <!---->
    </div>
    <!-- 打开红包 -->
    <redEnvelope v-if="isredEnvelope" :isredEnvelope.sync = 'isredEnvelope'></redEnvelope>

  </div>
</template>
<script>
import NProgress from 'nprogress';
import Notification from '../src/components/Notification.vue'
import redEnvelope from '../src/components/redEnvelope.vue'
export default {
  components:{
    Notification,
    redEnvelope
  },
  data() {
    return {
      istoken: true, //是否登录
      phone: "", //手机
      password: "", //密码
      passworduser: "", //用户记住的密码  因为input双向绑定会导致上面的密码清除
      verification: "", //验证码
      invitation:'', //邀请码
      tel: "", //正则效验
      count: "", //倒计时
      iscount: true, //倒计时
      isrouter:0,//添加下划线
      username:'',//昵称
      isBar:false,//手机适配导航栏
      vertoken:'',//短信验证码token
      vertype:true,//短信的类型
      istokens:false,//是否有token
      monitordata:[],//中奖轮播图
      newboxadd:[],//箱子最新最佳旧的
      newboxposition:1,//箱子位置
      isnewbox:true,//中奖滚动条显示
      scrollPosition:143,//默认首次滚动位置
      isNineLattice:true,//九宫格活动页
      getRollingPrize:'',//滚动抽奖
      isuser:false,//是否记住密码
      isusernanme:'',//用户姓名
      isuserlogo:'',//用户头像
      isredEnvelope:false,//红包组件
    };
  },
  watch:{
    $route(to){
      this.isBar = false
      //路由变化是关闭红包组件
      this.isredEnvelope = false
      if(to.path == '/'){
        this.isrouter  = 1
        document.getElementsByClassName("notice_san_big")[0].style.height = 42 + "px";//通知栏
        document.getElementsByClassName("out")[0].style.height = 42 + "px";//通知栏
        document.getElementsByClassName("notice_san_big_big")[0].style.display = 'block';//通知栏
        document.getElementsByClassName("out")[0].style.display = 'block';//通知栏
        setTimeout( () => {
          document.getElementsByClassName("out")[0].style.zIndex = '11';
        },200)
      }else if(to.path == '/Roll'){
        this.isrouter = 2
      }else if (to.path == '/integral'){
        this.isrouter = 3
      }else if (to.path == '/agency'){
        this.isrouter = 4
      }else if(to.path == '/recharge'){
        this.isrouter = 5
      }else if(to.path == '/freeBox'){
        this.isrouter = 6
      }else if(to.path == '/recaption'){
        this.isrouter = 7
      }else if(to.path == '/battleBox'){
        this.isrouter = 8
      }else{
        this.isrouter = 0
      }
      //下面是判断通知栏和滚动抽奖轮播图在某些页面不展示
      if(to.path == '/userinfo'){
        this.$store.commit("isroute", 1);
        this.isnewbox = false
        document.getElementsByClassName("notice_san_big_big")[0].style.display = 'none';//通知栏
      }else if(to.path == '/recaption'){
        this.$store.commit("isroute", 2);
        this.isnewbox = false
        document.getElementsByClassName("notice_san_big_big")[0].style.display = 'none';//通知栏
      }else if(to.path == '/agency'){
        this.$store.commit("isroute", 3);
        this.isnewbox = false
        document.getElementsByClassName("notice_san_big_big")[0].style.display = 'none';//通知栏
      }else if(to.path == '/record'){
        this.$store.commit("isroute", 4);
        this.isnewbox = false
        document.getElementsByClassName("notice_san_big_big")[0].style.display = 'none';//通知栏
      }else if(to.path == '/NineLattice'){
        this.isnewbox = false
      // 九宫格活动页关闭导航栏
      }else{
        this.$store.commit("isroute", 1);
        this.isnewbox = true
      }

      // 九宫格活动页关闭导航栏
      if(to.path == '/NineLattice'){
        this.isNineLattice = false
      }else{
        this.isNineLattice = true
      }
    },
    listData(){
      //触发最新最佳奖品
      this.getmonitor()
      // this.small()

    }
  },
  methods: {
    //用token请求用户信息
    tokenuser(){
      this.$axios({
        url:"getUserInfo",
        method:"post",
        params: {
          token:localStorage.getItem("token")
        },
      }).then( res => {
          const data = res.data
          if(data.status === 200){
          this.$store.commit("userdata", data.data); //用户信息保存到vuex   this.$store.state.userdata
          //this.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("token");
          this.istoken = false;
        }else{
          this.$message({
            showClose: true,
            message: data.msg,
            type: 'error'
          });
          this.isout()
        }
      });
    },
    //注册
    handleD() {
      document.getElementsByClassName("form-wnd")[0].classList.add("active");
      document.getElementsByClassName("popup-bg")[0].classList.add("active");
      document.getElementsByClassName("switch-block")[1].classList.add("active");
      document.getElementsByClassName("switch-block")[0].classList.remove("active");
      document.getElementsByClassName("button")[1].classList.add("active");
      document.getElementsByClassName("button")[0].classList.remove("active");
      this.password=''
      this.verification= ''
      this.vertype = true
    },
    //登录
    handleZhu() {
      document.getElementsByClassName("form-wnd")[0].classList.add("active");
      document.getElementsByClassName("popup-bg")[0].classList.add("active");
      document.getElementsByClassName("switch-block")[0].classList.add("active");
      document.getElementsByClassName("switch-block")[1].classList.remove("active");
      document.getElementsByClassName("button")[0].classList.add("active");
      document.getElementsByClassName("button")[1].classList.remove("active");
      this.isBar = false;
      this.password=''
    },
    //关闭
    handleGuanbi() {
      document.getElementsByClassName("form-wnd")[0].classList.remove("active");
      document.getElementsByClassName("popup-bg")[0].classList.remove("active");
    },
    handleBU() {
      document.getElementsByClassName("button")[0].classList.add("active");
      document.getElementsByClassName("button")[1].classList.remove("active");
      document.getElementsByClassName("switch-block")[0].classList.add("active");
      document.getElementsByClassName("switch-block")[1].classList.remove("active");
      this.password=''
    },
    handleBUt() {
      document.getElementsByClassName("button")[1].classList.add("active");
      document.getElementsByClassName("button")[0].classList.remove("active");
      document.getElementsByClassName("switch-block")[1].classList.add("active");
      document.getElementsByClassName("switch-block")[0].classList.remove("active");
      this.password=''
    },
    handleWj() {
      document.getElementsByClassName("form-wnd")[0].classList.remove("active");
      document.getElementsByClassName("form-wnd")[1].classList.add("active");
      this.password=''
      this.verification= ''
      this.vertype = false
    },
    handleGuan() {
      document.getElementsByClassName("form-wnd")[1].classList.remove("active");
      document.getElementsByClassName("popup-bg")[0].classList.remove("active");
    },
    // 红包
    redPrize(){
      //原红包显示
      // document.getElementsByClassName('ivu-modal')[0].style.display="block";
      // document.getElementById('isquan').classList.add("backghui");
      if(!localStorage.getItem("token")){
      this.$message({
        showClose: true,
        message: '请先登录'
      });
      //提示登录框
      setTimeout( ()=> {
          this.handleZhu()
        },300)
      }else{
        this.isredEnvelope = true
      }
    },
    // 注册并登录
    handleRegister() {
      var check = document.getElementById("selectOne");//是否同意
      var isphone = /^1[3456789]\d{9}$/;
      var ispassword = /^.{6,16}$/
      //判断是否为空
      if (this.phone == "") {
        this.$message({
              showClose: true,
              message: '请输入账号'
        });
      } else if (!isphone.test(this.phone)) {
        this.$message({
              showClose: true,
              message: '请输入正确的手机号码'
        });
      }else if (this.password == "") {
        this.$message({
              showClose: true,
              message: '请输入密码'
        });
      }else if(!ispassword.test(this.password)){
        this.$message({
              showClose: true,
              message: '请输入正确的密码'
        });
      }else{
        if(check.checked){
          this.$axios({
            url:'/reg',
            method:'post',
            params:({
              phone:this.phone,
              passwd:this.password,
              code_phone:this.verification,
              code:this.invitation,
              token:this.vertoken
            })
          }).then ( res => {
            const data = res.data
            if(data.status === 200){
              this.$bus.$emit('Tokens')
              localStorage.setItem("token",res.data.token);
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "success",
              });
              //注册成功直接拿token请求一遍数据
              this.$store.commit("userdata", data.data);
              this.tokenuser()
              this.handleGuan()
              this.handleGuanbi()
            }else{
              this.$message({
                showClose: true,
                message: res.data.msg,
                type: "error",
              });
            }
          })
        }else{
          this.$message({
                showClose: true,
                message: '请确实是否同意使用条约和隐私政策'
          });
        }
        }
    },
    //获取验证码效验
    handleveri() {
      var isphone = /^1[3456789]\d{9}$/;
      if (this.phone == "") {
        this.$message({
              showClose: true,
              message: '请输入手机号码'
        });
      } else if (!isphone.test(this.phone)) {
        this.$message({
              showClose: true,
              message: '请输入正确的手机号码'
        });
      } else if(this.iscount){
        this.$axios({
          url:'/sendCode',
          params:({
            phone:this.phone,
            type: this.vertype ? '' : '1',
          })
        })
        .then( res => {
          const data = res.data
          if(data.status === 200){
            this.vertoken =res.data.token
            this.$message({
              showClose: true,
              message: res.data.msg,
              type: "success",
            });
            const TIME_COUNT = 60;
            this.count = TIME_COUNT;
            this.iscount = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                clearInterval(this.timer);
                this.count = "";
                this.iscount = true;
              }
            }, 1000);
          }else{
            this.$message({
              showClose: true,
              message: '验证码获取繁忙，请稍后再试',
              type: "error",
            });
          }
        })
      }
    },
    //登录
    handelLogin() {
      var isphone = /^1[3456789]\d{9}$/;
      var ispassword = /^.{6,16}$/
      var limit18 = document.getElementById("limit18");//是否同意
      var passw = document.getElementById("passw");//是否同意
      //判断是否是记住密码登录
      if(this.isuser){
          if(!limit18.checked){
              this.$message({
                  showClose: true,
                  message: '请确认是否已满18岁'
            });
            return
          }
          this.$axios({
          url: "/login",
          method: "POST",
          params: {
            phone: this.phone,
            passwd: this.passworduser,
          },
          }).then((res) => {
              this.$bus.$emit('Tokens')
              const data = res.data
              if(data.status === 200){
              this.$store.commit("userdata", data.data); //用户信息保存到vuex   this.$store.state.userdata
              // console.log(this.$store.state.userdata);
              this.$message({
                showClose: true,
                message: data.msg,
                type: 'success'
              });
              this.handleGuanbi()
              localStorage.setItem("token",data.token);
              this.tokenuser()
              //console.log(data.token);
              //this.$axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
              //this.$axios.defaults.headers.common['Authorization'] = data.token; //将token保存到axios头
              // this.$store.commit("token", data); //把token保存到vuex 读取this.$store.state.token
              this.istoken = false;
            }else{
              this.$message({
                showClose: true,
                message: data.msg,
                type: 'error'
              });
            }
          });
        return
      }
      //判断是否为空
      if (this.phone == "") {
        this.$message({
              showClose: true,
              message: '请输入账号'
        });
      } else if (!isphone.test(this.phone)) {
        this.$message({
              showClose: true,
              message: '请输入正确的手机号码'
        });
      }else if (this.password == "") {
        this.$message({
              showClose: true,
              message: '请输入密码'
        });
      }else if(!ispassword.test(this.password)){
        this.$message({
              showClose: true,
              message: '请输入正确的密码'
        });
      }else if(!limit18.checked){
        this.$message({
              showClose: true,
              message: '请确认是否已满18岁'
        });
      }else{
      this.$axios({
        url: "/login",
        method: "POST",
        params: {
          phone: this.phone,
          passwd: this.password,
        },
      }).then((res) => {
          this.$bus.$emit('Tokens')
          const data = res.data
          if(data.status === 200){
          this.$store.commit("userdata", data.data); //用户信息保存到vuex   this.$store.state.userdata
          // console.log(this.$store.state.userdata);
          this.$message({
            showClose: true,
            message: data.msg,
            type: 'success'
          });
          this.handleGuanbi()
          localStorage.setItem("token",data.token);
          this.tokenuser()
          //console.log(data.token);
          //this.$axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
          //this.$axios.defaults.headers.common['Authorization'] = data.token; //将token保存到axios头
          // this.$store.commit("token", data); //把token保存到vuex 读取this.$store.state.token
          this.istoken = false;
          //保存密码
          if(passw.checked){
            localStorage.setItem("pa",this.password);
            localStorage.setItem("php",this.phone);
            localStorage.setItem("username",this.$store.state.userdata.username);
            localStorage.setItem("userlogo",this.$store.state.userdata.logo);
          }else{
            localStorage.removeItem("pa");
            localStorage.removeItem("php");
            localStorage.removeItem("username");
            localStorage.removeItem("userlogo");
          }

          this.password = '';
        }else{
          this.$message({
            showClose: true,
            message: data.msg,
            type: 'error'
          });
        }
      });
      }
    },
    //重置
    handelReset() {
      var isphone = /^1[3456789]\d{9}$/;
      var ispassword = /^.{6,16}$/
      //判断是否为空
      if (this.phone == "") {
        this.$message({
              showClose: true,
              message: '请输入账号'
        });
      } else if (!isphone.test(this.phone)) {
        this.$message({
              showClose: true,
              message: '请输入正确的手机号码'
        });
      }else if (this.password == "") {
        this.$message({
              showClose: true,
              message: '请输入密码'
        });
      }else if(!ispassword.test(this.password)){
        this.$message({
              showClose: true,
              message: '请输入正确的密码'
        });
      }else{
      this.$axios({
        url: "/resetPass",
        method: "POST",
        params: {
          phone: this.phone,
          passwd: this.password,
          code_phone:this.verification,
          token:this.vertoken
        },
      }).then((res) => {
          const data = res.data
          if(data.status === 200){
          this.$message({
            showClose: true,
            message: data.msg,
            type: 'success'
          });
          this.handleGuanbi()
          localStorage.setItem("token",data.token);
          this.tokenuser()
          this.handleGuan()
          this.password = '';
        }else{
          this.$message({
            showClose: true,
            message: data.msg,
            type: 'error'
          });
        }
      });
      }
    },
    //退出登录
    isout() {
      localStorage.removeItem("token");
      this.$store.commit("token", "");
      this.$store.commit("userdata", "");
      this.istoken = true;
      this.isBar = false;
      this.$router.push('/')
      this.isuserpa()
    },
    //退出登录2 活动页退出 不用返回首页
    isout2() {
      localStorage.removeItem("token");
      this.$store.commit("token", "");
      this.$store.commit("userdata", "");
      this.istoken = true;
      this.isBar = false;
    },
    //右边功能条的展示
    functionbar() {
      var t = document.getElementsByClassName("sidebar")[0];
      // console.log(t.classList[1]);
      // console.log(t.classList.length);
      if (t.classList.length == "2") {
        t.className = t.className.replace(" sidebar_show", "");
      } else {
        t.className = t.className + " sidebar_show";
      }
    },
    //点击回到顶部
    hanledingbu() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    //手机适配导航栏
    handleisBar(){
      this.isBar = !this.isBar
    },
    //中奖轮播图首次加载执行
    big() {
        document.getElementsByClassName("bigop_li")[0].style.transition = "left 2s";
      document.getElementsByClassName("bigop_li")[0].style.left = "0px";
    },
    // //中奖轮播图n次加载执行
    small2() {
      document.getElementsByClassName("bigop_li")[0].style.transition = "left 0s";
      document.getElementsByClassName("bigop_li")[0].style.left = -(this.scrollPosition * this.newboxposition) + 'px';
      setTimeout( () => {
          document.getElementsByClassName("bigop_li")[0].style.transition = "left 2s";
          document.getElementsByClassName("bigop_li")[0].style.left = "0px";
      },100)
    },
    //获取中奖轮播图
    getmonitor(){
      this.$axios({
        url:'monitor/data',
        method:'post'
      }).then( res => {
        if(res.data.status == 200){
          let newboxs =JSON.parse(JSON.stringify(res.data.data))
          // this.newbox = res.data.data
          // console.log(this.newbox);
          // console.log(this.newboxposition);
          for(let i = 0;i < newboxs.length;i++){
            if(newboxs[i].w_id == this.newboxadd.w_id){
              if(i < 5){
                this.newboxposition = i
              }else{
                this.newboxposition = 5
              }
              this.newboxadd = newboxs[0]
              break;
            }
          }
          setTimeout( () => {
            this.monitordata = res.data.data
            document.getElementsByClassName("bigop_li")[0].style.left = -(this.scrollPosition * this.newboxposition) + 'px';
            this.small2()
          },500)
          //根据元素div大小决定滚动的位置 +10为边框大小(直接获取不到 要在页面渲染数据出来后获取)
          setTimeout( () => {
            this.scrollPosition = document.getElementsByClassName('bottomdiv')[0].offsetWidth + 10
          },1000)

        }
      })
    },
    //获取最新箱子掉落
    getnewBoxsadd(){
      this.$axios({
        url:'monitor/data',
        method:'post',
        data:{
          pid:this.$route.query.id
        }
      }).then( res => {
        if(res.data.status == 200){
          this.newboxadd = res.data.data[0]
        }
      })
    },
    //判断是否记住密码
    isuserpa(){
      if(localStorage.getItem("pa")){
        this.isuser = true
      }else{
        this.isuser = false
      }
      this.$nextTick( ()=>{
        if(localStorage.getItem("pa")){
          //账号
          this.phone = localStorage.getItem("php")
          //密码
          this.passworduser = localStorage.getItem("pa")
          this.isusernanme = localStorage.getItem("username")
          this.isuserlogo = localStorage.getItem("userlogo")
        }
      })
    },
    //切换其它账号
    switchUser(){
      this.isuser = false
    },
    //标题头跳转九宫格抽奖
    async hanledNine(){
      //请求轮播图
      let href = ''
      await this.$axios({
        url:"/get/carousel",
        method:'post',
      }).then( res => {
        href = res.data.data.data[0].href;
      })
      //跳转九宫格抽奖
      let host = 'https://' + window.location.host + href
      window.open(host)
    },
  },
  //监听
  computed: {
    listData() {
      return this.$store.state.newbox
    }
  },
  created(){
    this.getnewBoxsadd()
  },
  mounted() {
    //全局事件总线
    this.$bus.$on('outToken',this.isout2)
    this.getmonitor()
    //有token 用token请求个人信息
    if (localStorage.getItem("token")) {
      this.tokenuser()
    }
    // //判断是否有token 提示登录框
    // if (localStorage.getItem("token")) {
    //   this.tokenuser()
    // }else if(!sessionStorage.getItem('aa')){
    //   sessionStorage.setItem('aa',1)
    //   setTimeout( ()=> {
    //     this.handleZhu()
    //   },1000)
    // }
    //路由守卫
    this.$router.beforeEach((to,from,next) => {
      NProgress.start();
      //是否有token
      const hasToken = localStorage.getItem("token");
      //判断是否需要登录权限的页面
      if(to.path === "/userinfo" || to.path === "/agency" || to.path === "/recaption" || to.path === "/recharge" || to.path === "/record"){
          if(hasToken){  //判断是否有token
              next();  //正常跳转
          }else{
            NProgress.done();
            this.$message({
              showClose: true,
              message: '请先登录'
            });
            next("/")  //没有token直接跳转登陆页
            //提示登录框
            setTimeout( ()=> {
              this.handleZhu()
            },300)
          }
      }else if(to.path === "/NineLattice"){
          this.isNineLattice = false
          next();
      }else{
          next();  // 不需要登陆权限  所有人都可以浏览
      }
    })
    this.$router.afterEach(transition => {
      //结束进度条
      NProgress.done();
    });
    //判断是否邀请码链接进入
    setTimeout( () => {
      if(this.$route.query.code){
        sessionStorage.setItem("code",this.$route.query.code);
      }
      if(sessionStorage.getItem("code") ){
        this.invitation = sessionStorage.getItem("code")
      }
    },500)
    //判断是否保存密码
    setTimeout( () => {
      if(localStorage.getItem("pa")){
        //账号
        this.phone = localStorage.getItem("php")
        //密码
        this.passworduser = localStorage.getItem("pa")
        this.isusernanme = localStorage.getItem("username")
        this.isuserlogo = localStorage.getItem("userlogo")
      }
      this.isuserpa()
    },500)


    //定义获取中奖信息
    // 全局后置路由守卫
     this.$router.afterEach(transition => {
      if(transition.path == '/agency' || transition.path == '/userinfo' || transition.path == '/recaption' || transition.path == '/record' || transition.path == '/NineLattice'){
        clearInterval(this.getRollingPrize);
        this.getRollingPrize = ''
      }else{
        if(!this.getRollingPrize){
          this.getRollingPrize = setInterval(() => {
            this.$store.commit("newbox", !this.$store.state.newbox);
          }, (Math.floor(Math.random() * (30 -15  + 1)) + 15)*1000);
        }
      }
    });
  },
}
</script>

<style>
@import url("../src/assets/icon/iconfont.css");
/* 公共样式 */
/* 路由加载条样式 */
#nprogress .bar {
  background: #f78e2d !important;
}
*{
    margin: 0;
    padding: 0;
}
body{
  /* 解决 Ios 300毫秒延迟 */
  touch-action: manipulation;
}
i,em{
    font-style: normal;
}
#app{
  position: relative !important;
  max-height: 100vh !important;
  min-height: 100vh !important;
  max-width: 100% !important;
  margin: 0 auto !important;
  background: url('../src/assets/img/bigimg.png') top no-repeat !important ;
  background-attachment: fixed !important;
  background-size: cover !important;
  overflow: hidden !important;
  overflow-y: auto !important;
}
</style>
<style lang="less" scoped>
@import url("../src/assets/csscopy/index.css");
.page__banner {
  height: 220px;
}
.wrapper {
  width: 610px !important;
}
.islogin {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  .islogin_wallet {
    p:nth-child(1){
      font-size: 14px !important;
      margin-bottom: 8px !important;
    }
    p {
      height: 22px;
      font-size: 13px ;
      font-family: Microsoft YaHei;
      font-weight: 700 !important;
      padding-top: 0px !important;
      line-height: 22px;
      img{
        width: 20px;
        height: 20px;
      }
    }
  }
  .islogin_data {
    text-align: center;
    padding: 0 10px;
    img {
      width: 30px;
      height: 30px;
      border-radius: 50px;
    }
  }
  .islogin_out {
    p {
      cursor: pointer;
      font-weight: 100 !important;
      font-size: 13px !important;
      padding-top: 8px;
      padding-right: 30px;
    }
  }
}
.nav-item {
  justify-content: center !important;
}
.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
//红包
.pictureRed{
  background-image: url('../src/assets/img/red.png');
}
</style>

<style lang="less" scoped>
// 手机app适配
.headerapp{
  height: 5.25rem;
  background-color: #1A1922;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  display: none;
  justify-content: space-between;
  align-items: center;
  .logoimg{
    width: 20%;
    height: 50%;
  }
  .user_data_data{
    text-align: center;
    margin-right: 5px;
    .user_data_img{
      width: 40px;
      height: 40px;
      border-radius: 50px;
    }

  }
  .user_data{
    display: flex;
    align-items: center;

    .user_wallet{
      color: #FF9838;
      // font-family: Microsoft YaHei;
      font-weight: 700;
      font-size: 0.9rem;
    }
    .user_walletjf{
      font-size: 0.9rem;
    }
  }
  .user_data_token{
    display: flex;
    align-items: center;
  }
  .sign-in-button {
    outline: none;
    cursor: pointer;
    border: 1px solid #ff9838;
    border-radius: 8px;
    padding: 4px 16px;
    white-space: nowrap;
    color: #333;
    font-size: 14px;
    font-weight: 700;
    background-color: #ff9838;
    transition: all .4s;
    margin-right: 20px;
  }
  .user_name{
    font-family: Microsoft YaHei;
    font-weight: 700;
  }
  /deep/ .el-button--primary{
    padding: 10px;
  }
}
.mobileBar{
  position: absolute;
  top: 5.25rem;
  background: #282736;
  right: 0;
  padding: 7px 10px;
  z-index: 9999;
}
.mobileBar_li{
  padding:5px 10px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 700;
  color: #555361;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  i{
    font-size: 24px;
    padding-right: 5px;
  }
}
.mobileBar_lis{
  color: #00dbff;
}
.mobileBar_i{
  color: #FEB139;
}

@media screen and (max-width: 800px) {
    .page__nav-bar{
      display: none !important;
    }
    .headerapp{
      display: flex !important;
    }
    .form-wnd {
      width: 85%;
      top: 12% !important;
      left: 8%;
    }
    .roll-rooms{
      display: none !important;
    }
    .division{
      height: 80px !important;
    }
}
</style>

<style scoped lang="less">
.bigop{
  width: 100%;
}
.bigop_li{
  width: 100%;
  display: flex;
  position: relative;
  left: -22.5%;
}
.win_information{
// width: 50%;
width: 9.5rem;
height: 15%;
margin: 0 5px;
// overflow:hidden;
background-color: #1C1D32;
border-radius: 5px 5px 0 0 ;
text-align: center;
p{
  width: 100%;
  height: 45px;
  word-wrap:break-word;
  overflow:hidden;
  color: #67706a;
  font-weight: 700;
}
}
.win_information_data{
width: 100%;
height: 80%;
border-radius: 5px 5px 0 0 ;
background-size: 100% 100%;
img{
  width: 100%;
  height: 100px;
}
}
.bottomdiv{
background-color: #666;
height: 5px;
width: 9.5rem;
margin-top: 10px;
background-size: 100% 100%;
}

.win_information_user{
width: 100%;
height: 80%;
border-radius: 5px 5px 0 0 ;
display: none;
.win_information_user_img{
  width: 100%;
  height: 100px;
  background-repeat:no-repeat;
  background-size: 110% 110%;
  background-position: center;
  transition: all .5s;
  img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin-top: 10px;
  }
  h2{
    margin-top: 5px;
    // color: #ff9838;
  }
}
}
.win_information:hover{
  .win_information_data{
    display: none;
  }
  .win_information_user{
    display: block;
  }
}
.win_information_user_img:hover{
    transform: scale(1.1);
}
@media screen and (max-width: 800px) {
    .win_information{
      width: 20.5vw;
    }
    .win_information_user_img{
      height: 70px !important;
      img{
        width: 35px !important;
        height: 35px !important;
      }
      h2{
        font-size: 3vw;
      }
    }
    .win_information_data img{
      height: 70px !important;
    }
    .bottomdiv{
      width: 20.5vw;
    }
    .bigop{
      overflow-y: scroll !important;
    }
    .bigop::-webkit-scrollbar {
      display:none
    }
}

//英文翻译
.nav-item__texts{
  font-size: 12px;
}
//头部导航栏改动
.logo-item_img{
  margin-left: 4%;
}
.title_text{
  width: 300px;
}
.nav-item__text_m{
  font-size: 12px;
  margin-left: 5px;
}
//记住密码头像登录
.isuser{
  width: 100%;
  text-align: center;
  p{
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 700;
  }
  img{
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
  span{
    color: #236bc1;
    padding: 10px 0;
    cursor: pointer;
  }
}
</style>
