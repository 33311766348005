import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Socket from './socket/socket.js';
import './assets/csscopy/ios.css'
import store from './store';
import axios from "axios";
//vuex
import {mapState, mapActions} from "vuex";

import {
    Button,
    Message,
    MessageBox,
    Upload,
    Pagination,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Input,
    Form,
    FormItem,
    Col,
    DatePicker,
    TimePicker,
    Switch,
    InputNumber,
    Select,
    Option,
    Loading,
    Dialog,
    Drawer
} from 'element-ui'

Vue.config.productionTip = false
Vue.use(Button).use(Upload).use(Pagination).use(Dropdown).use(DropdownMenu).use(DropdownItem).use(Input).use(Form).use(FormItem).use(Col)
    .use(DatePicker).use(TimePicker).use(Switch).use(InputNumber).use(Select).use(Option).use(Dialog).use(Drawer);
Vue.prototype.$message = Message
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$loading = Loading.service;
//点击复制组件
import clipboard from 'clipboard';

Vue.prototype.clipboard = clipboard;

//把axios挂载到原型
Vue.prototype.$axios = axios;
// 后置拦截器 拦截 4100000 错误
axios.interceptors.response.use(response => {
    if (response?.data?.status === 410000) {
        Message({
            showClose: true,
            message: response?.data?.msg,
            type: 'error'
        });
        localStorage.clear();
        store.commit("token", "");
        store.commit("userdata", "");
        // this.istoken = true;
        // this.isBar = false;
        router.push('/')
        // 延迟 3 秒
        setTimeout(() => {
            // 刷新页面
            window.location.reload();
        }, 3000);

    }
    return response
}, error => {

    return Promise.reject(error)
})
//设置axios基准路径 每次请求都会加上改路径
// axios.defaults.baseURL = '/api'
axios.defaults.baseURL = "https://api.cxskins.com/api"
// axios.defaults.baseURL = "http://127.0.0.1:8000/api"

axios.interceptors.request.use(config => {
    let token = localStorage.getItem("token");
    if (token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
        config.headers.Authorization = token;
    }
    return config
}, error => {
    return Promise.reject(error)
})

//请求网站基本信息
axios({
    url: 'get/info',
    method: 'post'
}).then(res => {
    if (res.data.data.is_closed == 0) {
        store.commit("webdata", res.data.data);
        // document.getElementsByTagName("meta")[0].name = "keywords";
        // document.getElementsByTagName("meta")[0].content = res.data.data.keywords;
        document.getElementsByTagName("title")[0].innerText = res.data.data.web_name
    } else if (res.data.data.is_closed == 1 && res.data.data.closed_url != '' && document.domain == "www.cxskins.com") {

        window.location.href = res.data.data.closed_url;
    } else {
        store.commit("webdata", res.data.data);
    }
})
//定义获取中奖信息 放到APP.vue
// window.setInterval(() => {
//   store.commit("newbox", !store.state.newbox);
// }, (Math.floor(Math.random() * (30 -15  + 1)) + 15)*1000);

//路由加载条
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
//在页面跳转使用
// router.beforeEach((to,from,next) => {
//   //开始进度条
// NProgress.start();
// // 继续路由
// next();
// });
// // 全局后置路由守卫
// router.afterEach(transition => {
// //结束进度条
// NProgress.done();
// });
NProgress.configure({
    easing: 'ease',  // 动画方式
    speed: 500,  // 递增进度条的速度
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔
    minimum: 0.3, // 初始化时的最小百分比
})

let vm = new Vue({
    router,
    store,
    render: h => h(App),
    methods: {
        ...mapActions(["getSocketData"]),
    },
    computed: {
        ...mapState(["socketData"]),
    },
    mounted() {
        // document.dispatchEvent(new Event('custom-event'))
        document.dispatchEvent(new Event('custom-render-trigger')) // 预渲染
    },
    beforeCreate() {
        Vue.prototype.$bus = this //安装全局事件总线 此时的this指向Vue 可以使用vue里面的方法 如$on $emit
        //新建socket全局连接

        Socket.initWebSocket();
        Socket.onMessage((e) => {
            this.getSocketData(e);
        })

    },

}).$mount('#app');
//let vm = 每次切换路由回到顶部
router.afterEach(() => {
    vm.$el.scrollTop = 0;
});
