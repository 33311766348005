<template>
  <div class="backghui">
        <div class="big">
            <div class="red_box">
                <i class="iconfont out" @click="handleOut">&#xe697;</i>
                <div class="red_box_input">
                    <el-input v-model="input" placeholder="请输入内容"></el-input>
                    <div class="img" @click="receivePrize"></div>
                </div>
                <div class="task_box">
                    <div class="task_details" v-for="(item,index) in redData" :key="index">
                        <div class="task_prize_img">
                            <img :src=item.logo>
                        </div>
                        <div class="task_prize_data">
                            <div class="task_prize_title">
                                <h4>{{item.title}}</h4>
                                <button @click="handleShare">分享</button>
                            </div>
                            <p class="task_prize_rules" v-html="$options.filters.msg(item.content)"></p>
                            <p class="task_prize_percentage">
                                {{item.astrict == 1 ? '最多可领取':''}}
                                {{item.astrict == 1 ? item.coin:''}}
                            </p>
                            <p class="task_prize_percentage">
                                <span v-if="item.astrict_succ != item.astrict_sum">
                                    {{item.astrict == 1 ? '邀请人数': item.astrict == 2 ? '已砍价':''}}
                                </span>
                                <span v-if="item.astrict == 2">
                                    {{item.astrict_succ == item.astrict_sum ? '已完成':item.astrict_succ + ' $'  + ' / ' + item.astrict_sum + ' $'}}
                                </span>
                                <span v-if="item.astrict == 1">
                                    {{item.astrict_succ == item.astrict_sum ? '已完成': item.astrict_succ + ' / ' + parseInt(item.astrict_sum)}}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="activity_rules">
            <div class="activity_rules_data">
                <h4>活动规则</h4>
                <p>1.达到相应的条件并输入正确的口令即可领取红包</p>
                <p>2.红包口令在官网群内进行发送 <a style="color: #ff9838;" target="_blank" :href="'http://tool.gljlw.com/qq/?qq='+ this.$store.state.webdata.qq " >点击加群</a></p>
            </div>
        </div>
        <!-- 中奖弹出页 -->
        <Drawpr
        v-show="isDrawpr" 
        :zhongjiangin='addprizeData'
        :isoutDrawp.sync = 'isDrawpr'
        :summoney="mmoney"
        :isaddprizeData='isaddprizeData'
        ></Drawpr>
        <!-- 复制 -->
        <input style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;" type="text" name="" id="input" >
    </div>
</template>

<script>
import Drawpr from "../components/Drawprizes";
export default {
    props:['isredEnvelope'],
    components:{
        Drawpr
    },
    data() {
        return {
            input: '',//红包口令
            isDrawpr:false,//中奖页面
            addprizeData:[{back_logo: "",code: 0,logo: "",money: "",p_title: "",title: "",type_title: "",w_id: "",username:""}],//中奖的信息
            mmoney:'',//总价
            fullscreenLoading: false,//开启中
            redData:'',//红包信息
            w_id:'',//中奖枪ID
            s_id:'',//中奖枪ID
            isaddprizeData:0,//是否开启单独出售  0为否
        }
    },
    methods:{
        //关闭
        handleOut(){
            this.$emit('update:isredEnvelope', false);
        },
        //登录
        handleZhu() {
        document.getElementsByClassName("form-wnd")[0].classList.add("active");
        document.getElementsByClassName("popup-bg")[0].classList.add("active");
        document.getElementsByClassName("switch-block")[0].classList.add("active");
        document.getElementsByClassName("switch-block")[1].classList.remove("active");
        document.getElementsByClassName("button")[0].classList.add("active");
        document.getElementsByClassName("button")[1].classList.remove("active");
        // this.isBar = false;
        // this.password=''
        },
        // 分享
        handleShare(){
            //判断是否登录
            if(!localStorage.getItem("token")){
                this.$message({
                    showClose: true,
                    message: '请先登录'
                });
                // this.$router.push('/')
                //提示登录框
                setTimeout( ()=> {
                    this.handleZhu()
                },300)
            }else{
                // let _this = this;
                // let clipboard = _this.copyBtn;
                // clipboard.on('success', function() {
                //     _this.$message({
                //         message: '复制成功！感觉去分享把！',
                //         type: 'success',
                //         showClose: true,
                //     });
                // });
                // clipboard.on('error', function() {
                //     _this.$message({
                //         message: '复制失败，请手动选择复制！',
                //         type: 'error',
                //         showClose: true,
                //     });
                // });

                var input = document.getElementById("input");
                // input.value = this.redData[0].share + '?code=' + this.$store.state.userdata.code; // 修改文本框的内容
                input.value = this.redData[0].share.replace(/www.cxskins.com/g,'www.cxskins.com/?code=' + this.$store.state.userdata.code); // 修改文本框的内容
                input.select(); // 选中文本
                document.execCommand("copy"); // 执行浏览器复制命令
                this.$message({
                    message: '复制成功！赶紧去分享把！',
                    type: 'success',
                    showClose: true,
                });
            }
        },
        // 领取奖品
        receivePrize(){
            //判断是否登录
            if(!localStorage.getItem("token")){
                this.$message({
                    showClose: true,
                    message: '请先登录'
                });
                // this.$router.push('/')
                //提示登录框
                setTimeout( ()=> {
                    this.handleZhu()
                },300)
            }else if(this.input === ''){
                this.$message({
                    showClose: true,
                    message: '请输入红包口令'
                });
            }else{
                const loading = this.$loading({
                    lock: true,
                    text: '红包开启中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$axios({
                    url:'/redpt/get',
                    method:'post',
                    data:{
                        passwd:this.input
                    }
                }).then( res => {
                    if(res.data.status === 200){
                        //判断是红包还是枪械 机械有data
                        if(res.data.data){
                            this.s_id = res.data.data.sid
                            this.w_id = res.data.data.w_id
                            //获取库存id
                            this.$axios({
                                url:'/pond/get/ShopId?id=' + parseInt(this.s_id),
                                method:'post',
                            }).then ( res => {
                                if(res.data.status === 200){
                                    loading.close();
                                    this.addprizeData[0].back_logo = res.data.data[0].back_logo
                                    this.addprizeData[0].title = res.data.data[0].title
                                    this.addprizeData[0].money = res.data.data[0].money
                                    this.addprizeData[0].type_title = res.data.data[0].type_title
                                    this.addprizeData[0].logo = res.data.data[0].logo
                                    this.addprizeData[0].w_id = this.w_id
                                    this.mmoney = res.data.data[0].money
                                    this.isDrawpr = true
                                }else{
                                    loading.close();
                                    this.$message({
                                        showClose: true,
                                        message: res.data.msg,
                                        type: 'success'
                                    });
                                }
                            })
                        }else{
                            loading.close();
                            this.$message({
                                showClose: true,
                                message: res.data.msg,
                                type: 'success'
                            });
                        }
                    }else{
                        loading.close();
                        this.$message({
                            showClose: true,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
                // setTimeout(() => {
                //     loading.close();
                //     this.isDrawpr = true
                // }, 2000);
            }
        },
        //获取红包
        getRed(){
            this.$axios({
                url:'/redpt/get/list',
                method:'post',
                data:{
                    page:1,
                    limit:10
                }
            }).then (res => {
                if(res.data.status === 200){
                    this.redData = res.data.data.data
                }
            })
        },
    },
    mounted(){
        this.getRed()
        //分享   获取元素
        this.$nextTick(()=>{
            // this.copyBtn = new this.clipboard(this.$refs.copy);
        })
    },
    //html过滤器 
    filters: {
        msg:function (msg) {
        return  msg
            .replace(/&amp;/g,'&')
            .replace(/&lt;/g, "<")
            .replace(/&gt;/g, ">")
            .replace(/&quot;/g, "\"")
            .replace(/&#39;/g, "\'")
            .replace(/&nbsp;/g, " ");
        },
    }
}
</script>

<style scoped lang="less">
.backghui{
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 12;
}
.big{
    width: 500px;
    margin:6vw auto 0;
}
.red_box{
    background-image: url('../assets/img/redback.png');
    width: 500px;
    height: 650px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    position: relative;
    margin: auto;
}
.out{
    font-size: 25px;
    position: absolute;
    right: -25px;
    color: #eee;
    cursor: pointer;
}
.red_box_input{
    position: absolute;
    height: 60px;
    top: 40%;
    left:50%;
    transform:translate(-50%,-20%); //参考自身定位居中
    .img{
        width: 58%;
        height: 100%;
        position: absolute;
        right: -40px;
        top: -14px;
        background-image: url("../assets/img/redadd.png");
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: 110% 120%;
        cursor: pointer;
    }
}
/deep/ .el-input__inner{
    background-color: #242018;
    border: 1px solid #cbbbb9;
    border-radius: 28px;
    height: 35px;
}
.task_box{
    position: absolute;
    top: 50%;
    margin: 0 5%;
    width: 90%;
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}
.task_details{
    margin-bottom: 15px;
    display: flex;
    border: 1px solid #4e4a3c;
    background-color: #2e291f;
    border-radius: 16px;
    align-items: center;
}
.task_prize_img{
    width: 100px;
    margin: 10px;
    height: 100px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
    img{
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        border-radius: 5px;
    }
}
.task_prize_data{
    width: 70%;
    margin: 10px;
}
.task_prize_title{
    display: flex;
    justify-content: space-between;
    align-content: center;
    h4{
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        white-space: nowrap;
        font-size: 16px;
        font-weight: 700;
        color: #ff9838;
    }
    button{
        cursor: pointer;
        outline: none;
        border: 1px solid #fff;
        border-radius: 8px;
        -webkit-box-shadow: 0 3px 0 0 rgb(40 11 31 / 39%);
        box-shadow: 0 3px 0 0 rgb(40 11 31 / 39%);
        padding: 4px 12px;
        font-size: 12px;
        font-weight: 700;
        color: #fff;
        background-color: #5e5b55;
        -webkit-transition: background-color .4s linear;
        transition: background-color .4s linear;
    }
}
.task_prize_rules{
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    margin: 6px 0;
    text-align: justify;
    font-size: 12px;
    color: #d2d1d1;
    margin: 10px 0;
}
.task_prize_percentage{
    color: #ffe74d;
}
.activity_rules{
    position: relative;
    width: 350px;
    border-radius: 0 0 24px 24px;
    margin: auto;
    background-color: #252118;
}
.activity_rules_data{
    padding: 10px;
    height: 130px;
    overflow-x: hidden;
    overflow-y: auto;
    h4{
        text-align: center;
        font-size: 16px;
        color: #c7c6c6;
    }
    p{
        overflow-y: auto;
        margin: 8px 0;
        text-align: justify;
        font-size: 14px;
        font-weight: 700;
        color: #c7c6c6;
    }
}
@media screen and (max-width: 800px) {
    .red_box{
        width: 100%;
        height: 70vh;
    }
    .task_box{
        height: 200px;
    }
    .backghui{
        z-index: 201;
    }
    .big{
        width: 85%;
        margin: 20vw auto 0;
    }
    .activity_rules{
        width: 55%;
    }
    .activity_rules_data{
        height: 80px;
    }
}
</style>