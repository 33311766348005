import Vue from "vue";

let Socket = {
    _this:this,
    initWebSocket() {//建立连接  
        if (typeof (WebSocket) === "undefined") {
            alert("您的浏览器不支持socket,请更换浏览器~~~")
            return
        }
        var _this = this;
        //初始化weosocket
        const wsuri = "wss://api.cxskins.com/websocket"
        //建立连接
        this.websock = new WebSocket(wsuri);
        //连接成功
        this.websock.onopen = function(e) {//连接成功事件
            //开启心跳
            _this.start();
        };
        //连接错误
        this.websock.onerror = function(e) {//连接失败事件
            //错误
            console.log("WebSocket连接发生错误");
            //重连
            _this.reconnect();
        };
        this.websock.close = function(e) {//连接关闭事件
            // //重连
            
            _this.reconnect();
            
        };
        return true;
    },
    /**
     * 获取数据回调
     * @param {*} callback 回调函数
     */
    onMessage(callback){
        
        this.websock.onmessage = (event) => {
            try{
                let data = JSON.parse(event.data);
                callback(data);
                this.reset();
            }catch(e){
                console.log(e);
            }
            
        }
    },
    reconnect() {//重新连接
        var that = this;
        if (that.lockReconnect) {
            return;
        };
        that.lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        that.timeoutnum && clearTimeout(that.timeoutnum);
        that.timeoutnum = setTimeout(function () {
            //新连接
            that.initWebSocket();
            that.lockReconnect = false;
        }, 5000);
    },
    reset() {//重置心跳
        //清除时间
        clearTimeout(this.timeoutObj);
        clearTimeout(this.serverTimeoutObj);
        //重启心跳
        this.start();
 
    },
    start() {//开启心跳
        
        var self = this;
       
        self.timeoutObj && clearTimeout(self.timeoutObj);
        self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
        self.timeoutObj = setTimeout(function () {
           
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            if (self.websock.readyState == 1) {//如果连接正常
                self.websock.send('{"type":"ping","code":0}');
            } else {//否则重连
                self.reconnect();
            }
            self.serverTimeoutObj = setTimeout(function () {
                //超时关闭
                self.websock.close();
            }, 4000);

        }, 4000)
    },
    websocketonmessage(event) {//接收服务器推送的信息
        //打印收到服务器的内容

        let data = JSON.parse(event.data)
        // console.log(data);
        if (data.type == 'init') {
            this.client_id = data.client_id
            //this.bind()
        } else if (data.code == 0 && data.msg == '成功') {
            this.BattlerecordList = []
            data.data.room.map((item) => {
                // 把箱子id取出来转换为数组  然后数组去遍历出所有id对应的箱子 拿到数组  放会每个item里面
                let arr = []
                let lengt = item.info.pond_id.split(',')
                for (let i = 0; i < lengt.length; i++) {
                    for (let j = 0; j < item.pond.length; j++) {
                        if (lengt[i] == item.pond[j].id) {
                            arr.push(item.pond[j])
                        }
                    }
                }
                item.newpond = arr
                this.BattlerecordList.push(item)
            })
        } else if (data.type == 'against_create') {
            var searchList = []
            searchList.push(data.data)
            searchList.map((item) => {
                // 把箱子id取出来转换为数组  然后数组去遍历出所有id对应的箱子 拿到数组  放会每个item里面
                let arr = []
                let lengt = item.info.pond_id.split(',')
                for (let i = 0; i < lengt.length; i++) {
                    for (let j = 0; j < item.pond.length; j++) {
                        if (lengt[i] == item.pond[j].id) {
                            arr.push(item.pond[j])
                        }
                    }
                }
                item.newpond = arr
                this.BattlerecordList.unshift(item)
                if (this.sendpage == 2 && this.BattlerecordList.length == 10) {
                    this.BattlerecordList.pop();
                }

            })
        } else if (data.type == 'against_rank') {
            this.top10Data = data.data
        } else if (data.type == 'against_add') {
            for (let i = 0; i < this.BattlerecordList.length; i++) {
                if (this.BattlerecordList[i].info.id == data.data.info.id) {
                    this.BattlerecordList[i].user = data.data.user
                }
            }
        } else if (data.type == 'against_suclos') {
            for (let i = 0; i < this.BattlerecordList.length; i++) {
                //循环拿到第几个房间
                if (this.BattlerecordList[i].info.id == data.id) {
                    this.BattlerecordList[i].info.state = 2
                    setTimeout(() => {
                        this.BattlerecordList[i].info.state = 1

                        //循环拿到获胜者
                        for (let o = 0; o < this.BattlerecordList[i].user.length; o++) {
                            for (let p = 0; p < data.data.length; p++) {
                                if (this.BattlerecordList[i].user[o].uid == data.data[p].uid) {
                                    this.BattlerecordList[i].user[o].win = 1
                                }
                            }
                        }
                    }, (Math.floor(Math.random() * (7 - 3 + 1)) + 3) * 1000);
                }
            }
        }else if (data.type == "ping"){
            return true;
        }
       
    }
}
export default Socket;