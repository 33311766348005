<template>
  <div class="notice_san_big_big">
        <div class="notice_san_big">
            <span class="notice_icon"><i class="iconfont">&#xe601;</i></span>
            <div class="notices">
                <div class="notice_datas">
                    <span>{{this.$store.state.webdata.web_centont}}</span>
                </div>
            </div>
            <i class="iconfont out" @click="handleOut">&#xe697;</i>
        </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            timer:'',//定时器
            sum:'',//窗口和通知栏的差别大小
        }
    },
    methods:{
        //关闭
        handleOut(){
            document.getElementsByClassName("out")[0].style.zIndex = '-1';
            setTimeout( () => {
                document.getElementsByClassName("notice_san_big")[0].style.height = 0 + "px";
                document.getElementsByClassName("out")[0].style.height = 0 + "px";
                document.getElementsByClassName("notice_san_big")[0].style.overflow = 'hidden';
                document.getElementsByClassName('notice_datas')[0].style.marginLeft = '-' + 0 + 'px'
            },200)
        },
        //滚动
        rollingbar(){
            clearInterval(this.timer);
            document.getElementsByClassName('notice_datas')[0].style.marginLeft = '-' + 0 + 'px'
            //获取窗口大小
            let winWidth = document.body.clientWidth;
            // 获取通知栏文字长度
            let bar = document.getElementsByClassName('notice_datas')[0].offsetWidth
            if(bar > winWidth){
                this.sum = bar - winWidth + 100 //换算下来是85的，加多一点是为了阅读
                let i = 1
                this.timer = setInterval(() => {
                    if(this.sum <= i){
                        document.getElementsByClassName('notice_datas')[0].style.marginLeft = '-' + 0 + 'px'
                        i = 1
                    }else{
                        i++
                        document.getElementsByClassName('notice_datas')[0].style.marginLeft = '-' + i + 'px'
                    }
                }, 20);
            }
        },
    },
    mounted(){
        this.$nextTick(()=>{
            window.addEventListener('resize', () => { //监听浏览器窗口大小改变
                //浏览器变化执行动作
                this.rollingbar()
            });
        })
        setTimeout( () =>{
            //到时候请求回来二点时候触发
            this.rollingbar()
        },2000)        
    },

}
</script>

<style lang="less" scoped>
.big{
    margin-top: 5px;
}
.notice_san_big{
    display: flex;
    // padding: 0 20px;
    align-items: center;
    background-image:-webkit-linear-gradient(top,#85660c,#5a4506);
    transition: all .3s linear;
    // overflow: hidden;
    height: 42px;
}
.notice_icon{
    padding:0 10px;
    z-index: 10;
    background-image: -webkit-linear-gradient(top,#85660c,#5a4506);
    i{
        font-size: 25px;
        color: #E9B10E;
    }
}
.notices{
    height: 42px;
    white-space:nowrap;
    // overflow:hidden;
    line-height: 42px;
}
.notice_datas{
    height: 42px;
    span{
        padding-right: 5px;
        font-size: 15px;
        color: #E9B10E;
    }
}
.out{
    // margin-left: auto;
    cursor:pointer;
    transition: all .3s linear;
    z-index: 11;
    color: #ffc30f;
    position: absolute;
    right: 0px;
    height: 42px;
    width: 30px;
    text-align: center;
    line-height: 42px;
    background-image:-webkit-linear-gradient(top,#85660c,#5a4506);
}
</style>