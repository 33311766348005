import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue')
  },
  {
    path: '/indexList',
    name: 'indexList',
    component: () => import('../views/indexList.vue')
  },
  {
    path: '/Roll',
    name: 'Roll',
    component: () => import('../views/Roll.vue'),
    meta:{
      isBack:false
    }
  },
  {
    path: '/rollDetail',
    name: 'rollDetail',
    component: () => import('../views/rollDetail.vue')
  },
  {
    path: '/integral',
    name: 'integral',
    component: () => import('../views/integral.vue')
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('../views/recharge.vue')
  },
  {
    path: '/userinfo',
    name: 'userinfo',
    component: () => import('../views/userinfon.vue')
  },
  {
    path: '/recaption',
    name: 'recaption',
    component: () => import('../views/recaption.vue')
  },
  {
    path: '/agency',
    name: 'agency',
    component: () => import('../views/agency.vue')
  },
  {
    path: '/record',
    name: 'record',
    component: () => import('../views/record.vue')
  },
  {
    path: '/freeBox',
    name: 'freeBox',
    component: () => import('../views/freeBox.vue')
  },
  {
    path: '/freeBoxList',
    name: 'freeBoxList',
    component: () => import('../views/freeBoxList.vue')
  },
  {
    path: '/treatyPolicy',
    name: 'treatyPolicy',
    component: () => import('../views/treatyPolicy.vue')
  },
  {
    path: '/NineLattice',
    name: 'NineLattice',
    component: () => import('../views/NineLattice.vue')
  },
  {
    path: '/battleBox',
    name: 'battleBox',
    component: () => import('../views/battleBox.vue')
  },
  {
    path: '/RoomBoxBattle',
    name: 'RoomBoxBattle',
    component: () => import('../views/RoomBoxBattle.vue')
  },
]

const router = new VueRouter({
  mode: 'history',  //去掉url中的
  scrollBehavior: () => ({ y: 0,x : 0}), //路由跳转后页面回到顶部
  routes
})
//重复点击同一个路由是报错
const originalPush = VueRouter.prototype.push
   VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
export default router
